import APIService from "../common/https";
import { SOLUTION } from "../constants/article";
const ARTICLE_CATEGORY = "articleCategory";
const ARTICLE = "article";

// 文章&解決方案
//

export const apiQueryArticleCategory = (type) => APIService.query(ARTICLE_CATEGORY, { type });
// @desc   文章種類-新增 / 目前只能填 SOLUTION
export const apiCreateArticleCategory = (name) => {
  return APIService.post(ARTICLE_CATEGORY, { type: SOLUTION.key, name });
};
// @desc 串接  文章種類-修改 / 目前只能填 SOLUTION
export const apiUpdateArticleCategory = (id, { type, name }) => {
  return APIService.update(ARTICLE_CATEGORY, id, { type, name });
};
export const apiDeleteArticleCategory = (id) => APIService.delete(ARTICLE_CATEGORY, id);

// 文章
export const apiQueryUserArticle = (params) => APIService.query(ARTICLE, params);
export const apiGetUserArticle = (id) => APIService.get(ARTICLE, id);

export const apiQueryAdminArticle = (params) =>
  APIService.query(ARTICLE, params, { tokenRequired: true });
export const apiGetAdminArticle = (id) => APIService.get(ARTICLE, id, { tokenRequired: true });
export const apiCreateArticle = (data) => APIService.create(ARTICLE, data);
export const apiUpdateArticle = (id, data) => APIService.update(ARTICLE, id, data);
export const apiRemoveArticle = (id) => APIService.delete(ARTICLE, id);
