import APIService from "../common/https";

// Common
//
export const apiUpload = (file) => APIService.form("file", file);

// 登入及驗證
//
export const apiLogIn = (data) => APIService.create("login", data);
export const apiSignUp = (data) => APIService.create("account/create", data);
export const apiRefreshToken = (params) => APIService.createParams("refreshToken", params);

//寄送郵件
export const apiCreateMail = (data) => APIService.create("mail/send", data);

//reCAPTCHA
export const apiCreateRECAPTCHA = (params) => APIService.createParams("recaptcha", params);
