import { createRouter, createWebHistory } from "vue-router";
import JWT from "../common/cookies";

const routes = [
  {
    path: "/",
    component: () => import("../layout/Main.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("../views/user/Home.vue"),
      },
      {
        path: "/solution-service",
        name: "SolutionService",
        component: () => import("../views/user/SolutionService.vue"),
      },
      {
        path: "/solution-service/:id",
        name: "SolutionServiceDetails",
        component: () => import("../views/user/SolutionServiceDetails.vue"),
      },
      {
        path: "/company-hpi",
        redirect: "/company",
        component: () => import("../views/user/AboutView.vue"),
        children: [
          {
            path: "/company",
            name: "company",
            component: () => import("../views/user/AboutCompany.vue"),
          },
          {
            path: "/partner",
            name: "partner",
            component: () => import("../views/user/AboutPartner.vue"),
          },
          {
            path: "/customer",
            name: "customer",
            component: () => import("../views/user/AboutCustomer.vue"),
          },
        ],
      },
      {
        path: "/contact-hpi",
        redirect: "/contact",
        component: () => import("../views/user/Contact.vue"),
        children: [
          {
            path: "/contact",
            name: "contact",
            component: () => import("../views/user/ContactMessage.vue"),
          },
          {
            path: "/location",
            name: "location",
            component: () => import("../views/user/ContactLocation.vue"),
          },
        ],
      },
      {
        path: "/product",
        name: "product",
        component: () => import("../views/user/ProductList.vue"),
      },
      {
        path: "/product/:brandEn",
        name: "productDetail",
        component: () => import("../views/user/ProductDetail.vue"),
      },
      {
        path: "/news",
        name: "news",
        component: () => import("../views/user/NewsCategoryAll.vue"),
      },
      {
        path: "/news/:categoryId",
        name: "newsList",
        component: () => import("../views/user/NewsCategoryList.vue"),
      },
      {
        path: "/news/:categoryId/:id",
        name: "newsContent",
        component: () => import("../views/user/NewsContent.vue"),
      },
      {
        path: "/csr",
        name: "csr",
        component: () => import("../views/user/CSRReport.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("../views/user/Terms.vue"),
      },
    ],
  },
  {
    path: "/admin",
    component: () => import("../views/admin/Dashboard.vue"),
    children: [
      {
        path: "",
        name: "article",
        meta: { title: "最新消息", auth: true },
        component: () => import("../views/admin/NewsList.vue"),
      },
      {
        path: "banner",
        name: "banner",
        meta: { title: "輪播圖片", auth: true },
        component: () => import("../views/admin/BannerList.vue"),
      },
      {
        path: "solutions",
        name: "solutions",
        meta: { title: "解決方案", auth: true },
        component: () => import("../views/admin/SolutionList.vue"),
      },
      {
        path: "brand",
        name: "brandList",
        meta: { title: "廠商及經銷產品", auth: true },
        component: () => import("../views/admin/BrandList.vue"),
      },
      {
        path: "brand/:id",
        name: "brandDetail",
        meta: { title: "廠商明細", auth: true },
        component: () => import("../views/admin/BrandDetail.vue"),
      },
      {
        path: "product-list",
        name: "productCategory",
        meta: { title: "產品類型", auth: true },
        component: () => import("../views/admin/ProductList.vue"),
      },
      {
        path: "menu",
        name: "menu",
        meta: { title: "選單異動", auth: true },
        component: () => import("../views/admin/MenuList.vue"),
      },
      {
        path: "signup",
        name: "signup",
        meta: { title: "註冊", auth: true },
        component: () => import("../views/admin/Signup.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/NotFound.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to) {
    if (to.name === "csr") {
      return { behavior: "smooth" };
    }
    return {
      top: 0,
      behavior: "smooth",
    };
  },
});

router.beforeEach(async (to, from, next) => {
  if (to.meta?.auth && !JWT.getToken()) {
    next({ name: "login" });
  }
  next();
});

export default router;
