const ID_TOKEN_KEY = "hpicorpToken";
const ID_REFRESH_TOKEN_KEY = "hpicorpRefresToken";

const getToken = () => {
  return document.cookie.replace(/(?:(?:^|.*;\s*)hpicorpToken\s*=\s*([^;]*).*$)|^.*$/, "$1");
};

const saveToken = (token, expired) => {
  document.cookie = `${ID_TOKEN_KEY}=${token}; expires=${new Date(expired)}`;
};

const removeToken = () => {
  document.cookie = `${ID_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

const getRefreshToken = function token() {
  return document.cookie.replace(/(?:(?:^|.*;\s*)hpicorpRefresToken\s*=\s*([^;]*).*$)|^.*$/, "$1");
};

const saveRefreshToken = function token(token, expired) {
  document.cookie = `${ID_REFRESH_TOKEN_KEY}=${token}; expires=${new Date(expired)}`;
};

const removeRefreshToken = () => {
  document.cookie = `${ID_REFRESH_TOKEN_KEY}=; expires=Thu, 01 Jan 1970 00:00:00 UTC;`;
};

export default {
  getToken,
  saveToken,
  removeToken,
  saveRefreshToken,
  getRefreshToken,
  removeRefreshToken,
};
