import APIService from "../common/https";
const tokenRequired = { tokenRequired: true };

export const apiQueryAdminBrand = () => APIService.query("brand", {}, tokenRequired);
export const apiQueryUserBrand = () => APIService.query("brand");
export const apiGetAdminBrand = (nameEn) => APIService.get("brand", nameEn, tokenRequired);
export const apiGetBrand = (nameEn) => APIService.get("brand", nameEn);

export const apiQueryAdminBrandBoard = () => APIService.query("brand/board", {}, tokenRequired);
export const apiGetAdminBrandBoard = (nameEn) =>
  APIService.get("brand/board", nameEn, tokenRequired);

export const apiQueryUserBrandBoard = (params = {}) => APIService.query("brand/board", params);
export const apiGetUserBrandBoard = (nameEn) => APIService.get("brand/board", nameEn);
export const apiCreateBrand = (data) => APIService.create("brand", data);
export const apiUpdateBrand = (id, data) => APIService.update("brand", id, data);
export const apiDeleteBrand = (id) => APIService.delete("brand", id);
